import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
// CUSTOM COMPONENT
import { MatxLoading } from "app/components";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/login`, { email, password });
    const { access_token, user, userInfo } = response.data;
    let appState = {
        isLoggedIn: true,
        access_token: access_token,
        user_info: userInfo
    };
    // save app state with user date in local storage
    localStorage["appState"] = JSON.stringify(appState);
    dispatch({ type: "LOGIN", payload: { user } });
  };

  const register = async (email, name, password, re_password) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/register`, { email, name, password, re_password });
    const { user } = response.data;

    dispatch({ type: "REGISTER", payload: { user } });
  };

  const logout = async () => {
    const appState = JSON.parse(localStorage.getItem('appState')) ?? null;

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/api/auth/logout`,
      headers: {
        'Authorization': `Bearer ${appState.access_token}`
      }
    };

    axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    localStorage.removeItem('appState');
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const appState = JSON.parse(localStorage.getItem('appState')) ?? null;

    async function getUserInfo() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user`, {
          headers: {
            'Authorization': `Bearer ${appState.access_token}`
          }
        });
        dispatch({ type: "INIT", payload: { isAuthenticated: true, user: data.user } });
      } catch (e) {
        localStorage.removeItem('appState');
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    }

    (async () => {
      appState === null && dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      appState && getUserInfo();
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
