import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
// const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
// const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));

// TAP SETTING PAGE
const TapSettingList = Loadable(lazy(()=> import("app/views/pages/tap-setting/list/AppTapList")));
const TapSetting = Loadable(lazy(()=> import("app/views/pages/tap-setting/form/AppSettingTap")));

// User
const UserInfo = Loadable(lazy(() => import("app/views/pages/user/UserInfo")))
const KeyActive = Loadable(lazy(() => import("app/views/pages/other/KeyActive")))


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // Tap setting
      { path: "/tab-setting", element: <TapSettingList />, auth: authRoles.editor },
      { path: "/tab-setting/create", element: <TapSetting />, auth: authRoles.editor },
      { path: "/tab-setting/edit/:id", element: <TapSetting />, auth: authRoles.editor },
      { path: "/user", element: <UserInfo /> },
      { path: "/key", element: <KeyActive /> }
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  // { path: "/session/signup", element: <JwtRegister /> },
  // { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="/tab-setting" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
